import * as React from 'react';
import {ChevronRight} from '../../../../../icons/dist';
import s from './Arrow.scss';
import classNames from 'classnames';
import {withGlobals} from '../../../../../globalPropsContext';
import {IGalleryGlobalProps} from '../../../../../gallery/galleryGlobalStrategy';

export enum ArrowDirection {
  RIGHT = 'right',
  LEFT = 'left',
}

export interface ArrowProps extends IGalleryGlobalProps {
  onClick(): void;
  direction: ArrowDirection;
  className?: string;
  dataHook: string;
}

class ArrowWithoutGlobals extends React.Component<ArrowProps> {
  private readonly onClick = (event) => {
    event.stopPropagation();
    event.preventDefault();
    this.props.onClick();
  };

  public render() {
    const {
      direction,
      className,
      globals: {
        styleParams: {
          fonts: {
            gallery_imageCarouselArrowSize: {value: imageCarouselArrowSize},
          },
        },
      },
      dataHook,
    } = this.props;

    return (
      <button
        data-hook={dataHook}
        type={'button'}
        className={classNames(s.arrow, s[direction], className, s[imageCarouselArrowSize])}
        onClick={this.onClick}>
        <ChevronRight className={s.chevron} />
      </button>
    );
  }
}
export const Arrow = withGlobals(ArrowWithoutGlobals);
