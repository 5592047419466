/* istanbul ignore file: todo(eran): not running image tests on this file yet */

import React from 'react';
import classNames from 'classnames';
import {WowImage, ImageLoadingBehaviorOptions, ImageResizeOptions} from 'wix-ui-tpa';
import * as Icons from '@wix/wixstores-client-common-components/dist/src/icons/dist';
import {HoverType, MediaFrameMediaType, ProductType} from '../../../../types/galleryTypes';
import s from './ImageUiTpaWrapper.scss';
import {withGlobals} from '../../../../globalPropsContext';
import {IGalleryGlobalProps} from '../../../../gallery/galleryGlobalStrategy';

export enum DataHook {
  Wrapper = 'product-thumbnail-wrapper',
  Media = 'product-thumbnail-media',
  DefaultImage = 'default-image',
}

export interface ImageUiTpaWrapperProps extends IGalleryGlobalProps {
  product: any;
  className?: string;
  imageClassName?: string;
  mediaItemIndex?: number;
  resize?: ImageResizeOptions;
  forceAspectRatio: number;
  hoverType: HoverType;
}

const iconsByFileType = (product) => {
  const fileType = product?.digitalProductFileItems[0]?.fileType;
  const size = '120';

  const icons = {
    [MediaFrameMediaType.SECURE_DOCUMENT]: <Icons.DocumentDigitalProductDefaultImage size={size} />,
    [MediaFrameMediaType.SECURE_PICTURE]: <Icons.PictureDigitalProductDefaultImage size={size} />,
    [MediaFrameMediaType.SECURE_VIDEO]: <Icons.VideoDigitalProductDefaultImage size={size} />,
    [MediaFrameMediaType.SECURE_MUSIC]: <Icons.MusicDigitalProductDefaultImage size={size} />,
    [MediaFrameMediaType.SECURE_ARCHIVE]: <Icons.DocumentDigitalProductDefaultImage size={size} />,
  };

  return icons[fileType] || icons[MediaFrameMediaType.SECURE_DOCUMENT];
};

const isDigitalProduct = (product) => product.productType === ProductType.DIGITAL;

const defaultImage = (product) => {
  if (isDigitalProduct(product)) {
    return iconsByFileType(product);
  } else {
    return <Icons.PhysicalProductDefaultImage size={'120'} />;
  }
};

export const ImageUiTpaWrapperWithoutGlobals = ({
  product,
  className,
  mediaItemIndex,
  imageClassName,
  resize,
  forceAspectRatio,
  hoverType,
  globals: {
    experiments: {shouldRenderGalleryProductItemCarouselHover},
  },
}: ImageUiTpaWrapperProps) => {
  const mediaItem = product.media.length > mediaItemIndex && product.media[mediaItemIndex];
  const alt = product.media.altText || product.name;

  const loadingBehavior =
    shouldRenderGalleryProductItemCarouselHover && hoverType === HoverType.Carousel
      ? ImageLoadingBehaviorOptions.none
      : ImageLoadingBehaviorOptions.blur;

  return mediaItem ? (
    <div className={className} data-hook={DataHook.Wrapper} style={{aspectRatio: forceAspectRatio.toString()}}>
      <WowImage
        data-hook={DataHook.Media}
        src={mediaItem.url}
        sourceWidth={mediaItem.width}
        sourceHeight={mediaItem.height}
        alt={alt}
        className={imageClassName}
        fluid
        loadingBehavior={loadingBehavior}
        aspectRatio={forceAspectRatio}
        resize={resize}
      />
    </div>
  ) : (
    <div
      data-hook={DataHook.DefaultImage}
      className={classNames(s.defaultImage, className)}
      style={{width: mediaItem.width, height: mediaItem.height}}>
      {defaultImage(product)}
    </div>
  );
};

export const ImageUiTpaWrapper = withGlobals(ImageUiTpaWrapperWithoutGlobals);
